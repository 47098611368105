
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Pie, G2 } from '@ant-design/plots';

const DemoPie = (props) => {

    const G = G2.getEngine('canvas');

    const [data, setData] = React.useState([]);

    const {size} = props;


    useEffect(() => {
        asyncFetch();
        const intervalId = setInterval(asyncFetch, 10000);
        return () => clearInterval(intervalId);
    },[]);

    const asyncFetch = () => {
        fetch('https://api-arbitrum.d5.xyz/v1/grids/history/swap/0x8Eb76679F7eD2a2Ec0145A87fE35d67ff6e19aa6')
            .then((response) => response.json())
            .then((json) => handleData(json))
            .catch((error) => {
            console.log('fetch data failed', error);
        });
    };

    const handleData = (param) =>{

        const resp = filterTransactionsByTime(param.data.swaps, size)

        const groupedTransactions = resp.reduce((groups, tx) => {

            let amount = parseFloat(tx.quote_amount);
          
            const groupKey = amount < 0 ? 'sell' : 'buy';
          
            if (!groups.hasOwnProperty(groupKey)) {
              groups[groupKey] = 0;
            }

            // 如果是买入交易，将 quote_amount 转换成正数
            if (amount < 0) {
                amount = -amount;
              }
          
            groups[groupKey] += amount;
          
            return groups;
          }, {});

          const dataParams = [
            {
              type: '买',
              value: groupedTransactions.buy === undefined ? 0 : groupedTransactions.buy,
            },
            {
              type: '卖',
              value: groupedTransactions.sell === undefined ? 0 : groupedTransactions.sell,
            },
          ];
         setData(dataParams)
    }

    

    function filterTransactionsByTime(transactions, minutes) {
        const currentTimestamp = Date.now() / 1000;
        const seconds = minutes * 60;
      
        return transactions.filter((tx) => {
          const txTimestamp = new Date(tx.block_timestamp).getTime() / 1000;
          return currentTimestamp - txTimestamp <= seconds;
        });
    }
  



  const config = {
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.75,
    autoFit:false,
    color: ['#87dcb2', '#f04864'],
    label: {
      type: 'spider',
      formatter: (data, mappingData) => {
        const group = new G.Group({});
        group.addShape({
          type: 'circle',
          attrs: {
            x: 0,
            y: 0,
            width: 1,
            height: 2,
            r: 3,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: 'text',
          attrs: {
            x: 5,
            y: 4,
            text: `${data.type}`,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: 'text',
          attrs: {
            x: 5,
            y: 50,
            text: `${Number(data.value).toFixed(4)}ETH\n\n${Number(data.percent * 100).toFixed(0)}%`,
            fill: 'rgba(0, 0, 0, 0.65)',
            fontWeight: 700,
          },
        });
        return group;
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
  };
  return <Pie {...config} />;
};
export default DemoPie;
