import { Text, Link, Grid} from "@nextui-org/react";

export default function home() {
  const list = [
    // {
    //   title: "总消耗GAS",
    //   price: "$5.50"
    // }
  ];

  return (
    <>
      {/* <Text
        h1
        size={60}
        css={{
          textGradient: "45deg, $blue600 -20%, $pink600 50%"
        }}
        weight="bold"
      >
        GDX交易订单查询
      </Text> */}
      <Grid.Container>
        <Text blockquote>系统只提供GDX/ETH交易对的数据，所有信息均来自d5平台；本系统不做任何信息，请放心使用。有任何建议需求请联系<Link block color="secondary" href="https://twitter.com/Nuggets1024">Nuggets1024</Link></Text>
      </Grid.Container>
    </>
  );
}
