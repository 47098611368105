import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Treemap } from '@ant-design/plots';

const DemoBidirectionalBar = (props) => {

  const [childrenTree, setChildrenTree] = React.useState([]);

  const [orderBooks, setOrderBooks] = React.useState([]);
  
  // 初始化
    useEffect(() => {
      getOrderBooks();
      const intervalId = setInterval(getOrderBooks, 10000);
      return () => clearInterval(intervalId);
  },[]);

    let data = {
    name: 'root',
      children: childrenTree
    };

  /**
     * 获取订单薄数据
     */
   const getOrderBooks = async () => {
    const res = await fetch(
        `https://api-arbitrum.d5.xyz/v1/market/order_books/0x8Eb76679F7eD2a2Ec0145A87fE35d67ff6e19aa6`
    );
    const resp = await res.json();
    let buyArray = resp.data.lows.slice(0, 30);
    let selArray = resp.data.highs.slice(0, 30);
    let tree = [];
    if(props.type === 'buy'){
      buyArray.forEach(element => {
        tree.push({'name': Math.floor(Number(element.price) * 1e9) / 1e9, 'value': Number(element.amount_quote).toFixed(2)});
      });
    }else{
      selArray.forEach(element => {
        tree.push({'name': Math.floor(Number(element.price) * 1e9) / 1e9, 'value': Number(element.amount_base).toFixed(2)});
      });
    }
    setChildrenTree(tree)
    setOrderBooks(resp.data)
    
  }

  const getPriceRangeBidIndex = (boundary) => {

    let index1 = orderBooks.lows.findIndex(item => item.origin_boundary === boundary.boundary_lower);
    let index2 = orderBooks.highs.findIndex(item => item.origin_boundary === boundary.boundary_lower);
    let index3 = orderBooks.lows.findIndex(item => item.origin_boundary === boundary.boundary_upper);
    let index4 = orderBooks.highs.findIndex(item => item.origin_boundary === boundary.boundary_upper);
    
    if(index1 >= 0){
        return index1 + 1;
    }

    if(index2 >= 0){
        return index2 + 1;
    }

    if(index3 >= 0){
        return index3 + 1;
    }

    if(index4 >= 0){
        return index4 + 1;
    }
    return 0;
}

  const config = {
    data,
    colorField: 'name',
  };
  return <Treemap {...config} />;
};


export default DemoBidirectionalBar;