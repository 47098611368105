import { Navbar } from "@nextui-org/react";



export default function home(props){

  let { index } = props;

    return (
        <Navbar  >
          <Navbar.Toggle showIn="xs" />
          <Navbar.Brand
            css={{
              "@xs": {
                w: "12%",
              },
            }}
            isBordered variant="sticky"
          >
            
          </Navbar.Brand>
          <Navbar.Content
            variant="underline"
            hideIn="xs"
          >
           
            <Navbar.Link isActive={ index === 'index' || index === 'index' ? true :false } href="/">首页</Navbar.Link>
            <Navbar.Link isActive={ index === 'analysis' ? true :false } href="/analysis">统计分析</Navbar.Link>
            <Navbar.Link isActive={ index === 'distribution' ? true :false } href="/distribution">锁仓分布</Navbar.Link>
          </Navbar.Content>
          <Navbar.Content
            css={{
              "@xs": {
                w: "12%",
                jc: "flex-end",
              },
            }}
          >
          </Navbar.Content>
        </Navbar>
    );
}


