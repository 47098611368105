import {
    Table,
    Badge,
    Switch,
    Text,
    Radio,
    Input,
    Button,
    Grid,
    Card,
    Row,
    Container
} from "@nextui-org/react";
import React from "react";


import moment from 'moment';



export default function App() {

    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');


    const [checked, setChecked] = React.useState("open");
    const [loadingState, setLoadingState] = React.useState("");
    const [dateChecked, setDateChecked] = React.useState("all");
    const [address, setAddress] = React.useState("");
    const [ethPrice, setEthPrice] = React.useState("1700");
    const [gdxPrice, setGdxPrice] = React.useState("1.20");

    const [buyPointT1, setBuyPointT1] = React.useState("0");
    const [selPointT1, setSelPointT1] = React.useState("0");
    const [buyPointT2, setBuyPointT2] = React.useState("0");
    const [selPointT2, setSelPointT2] = React.useState("0");

    const [isAutoRefresh, setIsAutoRefresh] = React.useState(false);
    const [results, setResults] = React.useState([]);
    const [orderBooks, setOrderBooks] = React.useState([]);

    React.useEffect(() => {
        let intervalId; // 定时器变量
        if (isAutoRefresh) {
            // 如果开启了自动刷新，则每隔 3 秒钟更新一次表格数据
            intervalId = setInterval(() => handleSearch(), 8000);
        }
        return () => clearInterval(intervalId);
    }, [isAutoRefresh]);

    React.useEffect(() => {
        const intervalId = setInterval(getPrice, 5000);
        return () => clearInterval(intervalId);
    }, []);

    React.useEffect(() => {
        const intervalId = setInterval(getOrderBooks, 10000);
        return () => clearInterval(intervalId);
    }, []);

    React.useEffect(() => {
        getPointData();
        const intervalId = setInterval(getPointData, 100000);
        return () => clearInterval(intervalId);
    }, []);

    


    /**
     * 获取全网积分数据
     */
    const getPointData = async ()=>{

        const rankList = await getRankList();
        // 获取积分
        const orders = await Promise.all(
            rankList.map(async (address) => {
              const response = await fetch(`https://api-arbitrum.d5.xyz/v1/farm/farmers/${address}/stats?grid_address=0x8Eb76679F7eD2a2Ec0145A87fE35d67ff6e19aa6`);
              const data = await response.json();
              return data.data;
            })
          );
        let buyT1 = 0;
        let selT1 = 0;
        let buyT2 = 0;
        let selT2 = 0;
        orders.forEach(item =>{
            buyT1 += Number(item.base_stats.maker1_formula_volume);
            selT1 += Number(item.quote_stats.maker1_formula_volume);
            buyT2 += Number(item.base_stats.maker2_formula_volume);
            selT2 += Number(item.quote_stats.maker2_formula_volume);
        })
        setBuyPointT1(currency(Number(buyT1).toFixed(2)));
        setSelPointT1(currency(Number(selT1).toFixed(2)));
        setBuyPointT2(currency(Number(buyT2).toFixed(2)));
        setSelPointT2(currency(Number(selT2).toFixed(2)));
    }

    const currency = function(number){
        return new Intl.NumberFormat('ja-JP', { minimumFractionDigits: 3}).format(number);
    };

    // 获取排行榜数据
    const getRankList = async (size)=>{
        // 获取排行榜数据 
        const res = await fetch(`https://api-arbitrum.d5.xyz/v1/farm/leaderboard?address=&grid_address=0x8Eb76679F7eD2a2Ec0145A87fE35d67ff6e19aa6`);
        const rsp = await res.json();
        let rankList = rsp.data.leaderboards;
        rankList = rankList.map(({ address }) => address);
        return rankList;
    }


    /**
     * 获取gdx、eth价格
     */
    const getPrice = async ()=>{
        const data = {
            "addresses":["0x2F27118E3D2332aFb7d165140Cf1bB127eA6975d","0x82aF49447D8a07e3bd95BD0d56f35241523fBab1"]
        };
        const res = await fetch(
            `https://api-arbitrum.d5.xyz/v1/tokens/by_addresses`,{
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        const rsp = await res.json();
        let gdx_price = Number(rsp.data.tokens.filter(item => item.id == 46)[0].price_usd).toFixed(3);
        let eth_price = Number(rsp.data.tokens.filter(item => item.id == 3)[0].price_usd).toFixed(3);
        setEthPrice(eth_price);
        setGdxPrice(gdx_price);
    }

    function formatNumber(num) {
        if (num >= 1000000) {
          return (num / 1000000).toFixed(1) + 'm';
        } else if (num >= 1000) {
          return (num / 1000).toFixed(1) + 'k';
        }
        return num;
      }

    /**
     * 获取订单薄数据
     */
    const getOrderBooks = async () => {
        const res = await fetch(
            `https://api-arbitrum.d5.xyz/v1/market/order_books/0x8Eb76679F7eD2a2Ec0145A87fE35d67ff6e19aa6`
        );
        const data = await res.json();
        setOrderBooks(data.data)
    }

    function toFixedDown(num, decimalPlaces) {
        const factor = Math.pow(10, decimalPlaces);
        return (Math.floor(num * factor) / factor).toFixed(decimalPlaces);
    }


    /**
     * 获取价格范围
     * @param {*} boundary 
     * @returns 
     */
    const getPriceRange = (boundary) => {
        let index1 = orderBooks.lows.findIndex(item => item.origin_boundary === boundary.boundary_lower);
        let index2 = orderBooks.highs.findIndex(item => item.origin_boundary === boundary.boundary_lower);
        let index3 = orderBooks.lows.findIndex(item => item.origin_boundary === boundary.boundary_upper);
        let index4 = orderBooks.highs.findIndex(item => item.origin_boundary === boundary.boundary_upper);
        
        if(index1 >= 0){
            return orderBooks.lows.filter(item => item.origin_boundary === boundary.boundary_lower)[0];
        }

        if(index2 >= 0){
            return orderBooks.highs.filter(item => item.origin_boundary === boundary.boundary_lower)[0];
        }

        if(index3 >= 0){
            return orderBooks.lows.filter(item => item.origin_boundary === boundary.boundary_upper)[0];
        }

        if(index4 >= 0){
            return orderBooks.highs.filter(item => item.origin_boundary === boundary.boundary_upper)[0];
        }
        // let newArray = [];
        // newArray.push(orderBooks.current);
        return null;
    }

    /**
     * 获取价格档位
     * @param {*} boundary 
     * @returns 
     */
    const getPriceRangeBidIndex = (boundary) => {

        let index1 = orderBooks.lows.findIndex(item => item.origin_boundary === boundary.boundary_lower);
        let index2 = orderBooks.highs.findIndex(item => item.origin_boundary === boundary.boundary_lower);
        let index3 = orderBooks.lows.findIndex(item => item.origin_boundary === boundary.boundary_upper);
        let index4 = orderBooks.highs.findIndex(item => item.origin_boundary === boundary.boundary_upper);
        
        if(index1 >= 0){
            return index1 + 1;
        }

        if(index2 >= 0){
            return index2 + 1;
        }

        if(index3 >= 0){
            return index3 + 1;
        }

        if(index4 >= 0){
            return index4 + 1;
        }
        return 0;
    }

    const selectFS = () => {
        if (isAutoRefresh) {
            setIsAutoRefresh(false);
        } else {
            setIsAutoRefresh(true);
        }
    }
    

    const handleSearch = async () => {
        await getOrderBooks();
        setLoadingState('loading')
        const res = await fetch(
            `https://api-arbitrum.d5.xyz/v1/orders/${checked}/by_owner?owner=${address}&order_type=${checked}&address=&from=&limit=5000&direction=next`
        );
        const data = await res.json();
        // 只要gdx/eth订单
        let filterData = data.data.orders.filter(item => item.token0 === '0x2F27118E3D2332aFb7d165140Cf1bB127eA6975d')

        // 去重
        filterData = Array.from(new Set(filterData.map(item => item.tx_hash)))
            .map(txHash => {
                return filterData.find(item => item.tx_hash === txHash);
            });

        // id排序
        filterData = filterData.sort((a, b) => b.order_id - a.order_id)

        // 时间范围过滤
        if (dateChecked === 'today') {
            filterData = filterData.filter(item => moment(item.block_timestamp).isSameOrAfter(today, 'day'));
        } else if (dateChecked === 'yesterday') (
            filterData = filterData.filter(item => moment(item.block_timestamp).isSameOrAfter(yesterday, 'day') && moment(item.block_timestamp).isBefore(today, 'day'))
        )
        setResults(filterData);
        setLoadingState('')
    };

    const handleSearchClick = async () => {
        await getOrderBooks();
        setLoadingState('loading')
        setIsAutoRefresh(false)
        const res = await fetch(
            `https://api-arbitrum.d5.xyz/v1/orders/${checked}/by_owner?owner=${address}&order_type=${checked}&address=&from=&limit=5000&direction=next`
        );
        const data = await res.json();
        // 只要gdx/eth订单
        let filterData = data.data.orders.filter(item => item.token0 === '0x2F27118E3D2332aFb7d165140Cf1bB127eA6975d')

        // 去重
        filterData = Array.from(new Set(filterData.map(item => item.tx_hash)))
            .map(txHash => {
                return filterData.find(item => item.tx_hash === txHash);
            });

        // id排序
        filterData = filterData.sort((a, b) => b.order_id - a.order_id)

        // 时间范围过滤
        if (dateChecked === 'today') {
            filterData = filterData.filter(item => moment(item.block_timestamp).isSameOrAfter(today, 'day'));
        } else if (dateChecked === 'yesterday') (
            filterData = filterData.filter(item => moment(item.block_timestamp).isSameOrAfter(yesterday, 'day') && moment(item.block_timestamp).isBefore(today, 'day'))
        )
        setResults(filterData);
        setLoadingState('')
    };

    return (
        <>
            <Grid.Container gap={2}>
                <Grid xs={4}>
                    <Card isHoverable variant="bordered" css={{ mw: "400px" }}>
                        <Card.Body>
                            <h3>GDX/ETH汇率</h3>
                            <Text size={16}>${Number(gdxPrice / ethPrice).toFixed(9)}</Text>
                        </Card.Body>
                    </Card>
                </Grid>
                <Grid xs={4}>
                    <Card isHoverable variant="bordered" css={{ mw: "400px" }}>
                        <Card.Body>
                            <h3>GDX价格</h3>
                            <Text size={16}>${gdxPrice}</Text>
                        </Card.Body>
                    </Card>
                </Grid>
                <Grid xs={4}>
                    <Card isHoverable variant="bordered" css={{ mw: "400px" }}>
                        <Card.Body>
                            <h3>ETH价格</h3>
                            <Text size={16}>${ethPrice}</Text>
                        </Card.Body>
                    </Card>
                </Grid>
                <Grid xs={4}>
                    <Card isHoverable variant="bordered" css={{ mw: "400px" }}>
                        <Card.Body>
                            <h3>T1全网积分（买/卖）</h3>
                            <Text  size={16}>{buyPointT1} / {selPointT1}</Text>
                        </Card.Body>
                    </Card>
                </Grid>
                <Grid xs={4}>
                    <Card isHoverable variant="bordered" css={{ mw: "400px" }}>
                        <Card.Body>
                            <h3>T2全网积分（买/卖）</h3>
                            <Text size={16}>{buyPointT2} / {selPointT2}</Text>
                        </Card.Body>
                    </Card>
                </Grid>
            </Grid.Container>
            <Grid.Container gap={2}>
                <Grid xs={12}>
                    <Radio.Group
                        orientation="horizontal"
                        value={checked}
                        onChange={setChecked}
                        defaultValue="open"
                    >
                        <Radio value="open" >
                            委托订单
                        </Radio>
                        <Radio value="close" >
                            历史订单
                        </Radio>
                    </Radio.Group>
                </Grid>
            </Grid.Container>
            <Grid.Container gap={2}>
                <Grid xs={11}>
                    <Input
                        clearable
                        bordered
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        width="100%"
                        size="lg"
                        color="primary"
                        placeholder="输入钱包地址"
                    />
                </Grid>
                <Grid xs={1}>
                    <Button onClick={handleSearchClick} shadow size="lg" auto>
                        查询
                    </Button>
                </Grid>
            </Grid.Container>

            <Grid.Container gap={2}>
                <Grid xs={12}>
                    <Radio.Group value={dateChecked} onChange={setDateChecked} orientation="horizontal" defaultValue="all">
                        <Radio value="all">
                            全部
                        </Radio>
                        <Radio value="today">
                            今天
                        </Radio>
                        <Radio value="yesterday">
                            昨天
                        </Radio>
                    </Radio.Group>
                </Grid>
            </Grid.Container>

            <Grid.Container gap={1} justify="center">
                <Grid xs={11}>
                </Grid>
                <Grid xs={1}>
                    <Switch shadow color="success" onChange={selectFS} checked={isAutoRefresh} size="xs" />&nbsp;自动刷新
                </Grid>
            </Grid.Container>
            <Table
                shadow={true}
                color="secondary"
                aria-label="Example pagination  table"
                css={{
                    height: "auto",
                    minWidth: "100%"
                }}
            >
                <Table.Header>
                    <Table.Column>订单ID</Table.Column>
                    <Table.Column>交易对</Table.Column>
                    <Table.Column>类型</Table.Column>
                    <Table.Column>平均卖出价格</Table.Column>
                    <Table.Column>下单价格 / 价格范围</Table.Column>
                    <Table.Column>所在Bid</Table.Column>
                    <Table.Column>全部 / 已成交</Table.Column>
                    <Table.Column>赚取费用</Table.Column>
                    <Table.Column>下单时间</Table.Column>
                    <Table.Column>结算时间</Table.Column>
                    <Table.Column>相隔时间</Table.Column>
                    <Table.Column>状态</Table.Column>
                </Table.Header>
                <Table.Body loadingState={loadingState} >
                    {results.map((result) => (
                        <Table.Row key={result.id}>
                            <Table.Cell>{result.order_id}</Table.Cell>
                            <Table.Cell>GDX / ETH</Table.Cell>
                            <Table.Cell>
                                {result.zero === true ? (
                                    <Text color="error">卖出</Text>
                                ) : (
                                    <Text color="success">买入</Text>
                                )}
                            </Table.Cell>
                            <Table.Cell>
                                {toFixedDown(Number(result.avg_price), 9)}
                            </Table.Cell>
                            <Table.Cell>
                            {/* (Ξ {result.zero === true ? formatNumber(Number(getPriceRange(result).amount_base).toFixed(2)) + 'GDX' : formatNumber(Number(getPriceRange(result).amount_quote).toFixed(2)) + ' ETH'} */}
                                {getPriceRange(result) != null ? toFixedDown(Number(getPriceRange(result).price), 9) : '0.00'}
                                <br />
                                {getPriceRange(result) != null ? toFixedDown(Number(getPriceRange(result).lower_price), 9) : '0.00'}
                                ~
                                {getPriceRange(result) != null ? toFixedDown(Number(getPriceRange(result).upper_price), 9) : '0.00'}
                            </Table.Cell>
                            <Table.Cell>
                                {
                                    getPriceRangeBidIndex(result) === 0 ? '暂无报价' : getPriceRangeBidIndex(result) + '档'
                                }
                            </Table.Cell>
                            <Table.Cell>
                                {Number(result.maker_amount_in).toFixed(4)} <br />{" "}
                                {Number(result.maker_amount_swapped).toFixed(4)}
                            </Table.Cell>
                            <Table.Cell>
                                {result.taker_fee_amount_out > 0 ? (
                                    <Text color="success">
                                        +{Number(result.taker_fee_amount_out).toFixed(4)}{" "}
                                        {result.zero === true ? " ETH" : " GDX"}
                                    </Text>
                                ) : (
                                    <Text>0{result.zero === true ? " ETH" : " GDX"}</Text>
                                )}
                            </Table.Cell>
                            <Table.Cell>{moment(result.block_timestamp).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
                            <Table.Cell>{moment(result.settlement_timestamp).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
                            <Table.Cell>{moment(result.settlement_timestamp).diff(moment(result.block_timestamp), 'minute')}min</Table.Cell>
                            <Table.Cell>
                                {result.status === "ORDER_STATUS_FILLED" ? (
                                    <Text color="success">
                                        <>已成交</>
                                    </Text>
                                ) : result.status === "ORDER_STATUS_CANCELLED" ? (
                                    <>已取消</>
                                ) : result.status === "ORDER_STATUS_PARTIAL_FILLED" ? (
                                    <>部分成交</>
                                ) : (
                                    <>未成交</>
                                )}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
                <Table.Pagination
                    shadow
                    noMargin
                    align="center"
                    rowsPerPage={10}
                    onPageChange={(page) => console.log({ page })}
                />
            </Table>
        </>
    );
}