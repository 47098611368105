import { useState, useEffect } from "react";
import { Card, Text, Grid, Row, Button,Table } from "@nextui-org/react";
import DemoBidirectionalBar from './buy';
import DemoColumn from './plots';
import DemoPie from './pie';



const BuySellBoard = () => {
  

  useEffect(() =>  {
  }, []);
  

  return (
    <Grid.Container gap={1}>
      <Grid xs={6} >
        <Card css={{  }}>
          <Card.Header>
            <Text>实时挂单统计(买 ETH)</Text>
          </Card.Header>
          <Card.Body>
            <DemoBidirectionalBar type="buy"/>
          </Card.Body>
        </Card>
      </Grid>
      <Grid xs={6} >
        <Card css={{  }}>
          <Card.Header>
            <Text>实时挂单统计(卖 GDX)</Text>
          </Card.Header>
          <Card.Body>
            <DemoBidirectionalBar type="sel"/>
          </Card.Body>
        </Card>
      </Grid>


      <Grid xs={4} >
        <Card css={{  }}>
          <Card.Header>
            <Text>近1分钟交易额(ETH)</Text>
          </Card.Header>
          <Card.Body>
            <DemoPie size="1"/>
          </Card.Body>
        </Card>
      </Grid>
      <Grid xs={4} >
        <Card css={{  }}>
          <Card.Header>
            <Text>近5分钟交易额(ETH)</Text>
          </Card.Header>
          <Card.Body>
            <DemoPie size="5"/>
          </Card.Body>
        </Card>
      </Grid>

      <Grid xs={4} >
        <Card css={{  }}>
          <Card.Header>
            <Text>近10分钟交易额(ETH)</Text>
          </Card.Header>
          <Card.Body>
            <DemoPie size="10"/>
          </Card.Body>
        </Card>
      </Grid>

      <Grid xs={4} >
        <Card css={{  }}>
          <Card.Header>
            <Text>近30分钟交易额(ETH)</Text>
          </Card.Header>
          <Card.Body>
            <DemoPie size="30"/>
          </Card.Body>
        </Card>
      </Grid>

      <Grid xs={4} >
        <Card css={{  }}>
          <Card.Header>
            <Text>近60分钟交易额(ETH)</Text>
          </Card.Header>
          <Card.Body>
            <DemoPie size="60"/>
          </Card.Body>
        </Card>
      </Grid>
    </Grid.Container>
  );
};

export default BuySellBoard;
