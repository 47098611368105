import './App.css';
import BuySellBoard from "./component/BuySellBoard";
import Distribution from "./component/distribution";
import Head from "./component/Head";
import Body from "./component/Body";
import Nav from "./component/Nav";
import * as React from 'react';
import { NextUIProvider, Container } from '@nextui-org/react';
import { BrowserRouter,  Route, Router, Routes } from 'react-router-dom';



function App() {
  return (
    <BrowserRouter>
      <RouterView></RouterView>
    </BrowserRouter>
  );
}

function RouterView(){
  return (<div>
    <Routes>
    <Route path="/" element={(
        <>
        <NextUIProvider>
          <Container  gap={2} >
          <Nav index='index'/>
          <Head/>
          <Body/>
          </Container>
        </NextUIProvider>
        </>
      )}/>

      <Route path="/analysis" element={(
        <>
        <NextUIProvider>
          <Container  gap={2} >
          <Nav index='analysis'/>
          <BuySellBoard/>
          </Container>
        </NextUIProvider>
        </>
      )}/>

   <Route path="/distribution" element={(
        <>
        <NextUIProvider>
          <Container  gap={2} >
          <Nav index='distribution'/>
          <Distribution/>
          </Container>
        </NextUIProvider>
        </>
      )}/>
      
    </Routes>
  </div> )
}


function Home(){
  return(<div>
    <h1>2123</h1>
  </div>)
}

export default App;
