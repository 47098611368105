import { Table, Row, Col, Container, Button, Spacer, Text, Grid, Card} from "@nextui-org/react";
import { merge } from "lodash";
import DemoPie from "./positionPlost"

import React, { useState, useEffect } from 'react';

export default function App(){

    const [rows, setRows] = useState([]);
    const [loadingState, setLoadingState] = useState("");

    const currency = function(number){
        return new Intl.NumberFormat('ja-JP', { minimumFractionDigits: 3}).format(number);
    };


    // 获取排行榜数据
    const getRankList = async ()=>{
        // 获取排行榜数据 
        const res = await fetch(`https://api-arbitrum.d5.xyz/v1/farm/leaderboard?address=&grid_address=0x8Eb76679F7eD2a2Ec0145A87fE35d67ff6e19aa6`);
        const rsp = await res.json();
        let rankList = rsp.data.leaderboards;
        rankList = rankList.map(({ address }) => address);
        return rankList;
    }

    /**
     * 获取锁仓分布
     * @param {*} address 
     */
    const getLockPosition = async (address) =>{
        const response = await fetch(`https://api-arbitrum.d5.xyz/v1/farm/farmers/${address}`);
        const data = await response.json();
        const lockDays = data.data.lock_days;
        let position = 1;
        if(lockDays === 30){
            position = 5; 
        }else if(lockDays === 90){
            position = 20
        }else if(lockDays === 180){
            position = 100;
        }
        return { address, position }
    }

    /**
     * 获取积分数据
     * @param {*} address 
     * @returns 
     */
    const getPointData = async (address) =>{
        const response = await fetch(`https://api-arbitrum.d5.xyz/v1/farm/farmers/${address}/orders?grid_address=0x8Eb76679F7eD2a2Ec0145A87fE35d67ff6e19aa6&limit=10&from=0&direction=next`);
        const data = await response.json();
        const sum = data.data.orders.reduce((acc, curr) => {
            const quantity = Number(curr.mcsr);
            return acc + (isNaN(quantity) ? 0 : quantity);
          }, 0);
        return sum;
    }

    const fetchData = async () =>{

        setLoadingState('loading')

        // 获取排名
        const rankList = await getRankList();

        // 获取lock位置
        let positionMap = new Map();
        await Promise.all(
            rankList.map(async (rankAddr) => {
               let {address, position} = await getLockPosition(rankAddr);
               positionMap.set(address, position)
            })
        );

        // 获取积分
        let pointMap = new Map();
        await Promise.all(
            rankList.map(async (rankAddr) => {
               let point = await getPointData(rankAddr);
               pointMap.set(rankAddr, point)
            })
        );

        // 数据合并
        mergeData(rankList, positionMap, pointMap)

        setLoadingState('')
    }


    const mergeData = (rankList, positionMap, pointMap)=>{
        const rows = [];
        let i = 0;
        rankList.map((address) => {
            i++;
            rows.push({rank: i, address: address, point: pointMap.get(address), position: positionMap.get(address)})
        })
        setRows(rows);
    }

    const getXX = ()=>{
        if(rows.length > 0){
            return <DemoPie rawData={rows}/>;
        }else{
            return <Text h4>暂无数据</Text>;
        }
    }
    

    return (
        <>
         
        <Spacer y={0.5} />
            <Button size="sm" onClick={fetchData}>数据加载</Button>
        <Spacer y={0.5} />

        <Container gap={0} css={{ d: 'flex', flexWrap: 'nowrap' }}>
        <Col span="4">
        <Card css={{ mw: "530px", height: "570px"}}>
          <Card.Header>
            <Text b>锁仓分布</Text>
          </Card.Header>
          <Card.Divider />
          <Card.Body css={{ py: "$10" }}>
              {getXX()}
          </Card.Body>
        </Card>
        
        </Col>
        <Spacer x={1} />
      <Col span="10">
      <Table
        css={{
            height: "auto",
            minWidth: "100%",
        }}
        >
        <Table.Header>
            <Table.Column>排名</Table.Column>
            <Table.Column>地址</Table.Column>
            <Table.Column>当前积分(M*C*X*R)</Table.Column>
            <Table.Column>锁仓位</Table.Column>
        </Table.Header>
        <Table.Body loadingState={loadingState} items={rows}>
            {rows.map((result) => (
                <Table.Row key={result.rank}>
                    <Table.Cell>{result.rank}</Table.Cell>
                    <Table.Cell>{result.address}</Table.Cell>
                    <Table.Cell>{currency(Number(result.point).toFixed(2))}</Table.Cell>
                    <Table.Cell>{result.position}x</Table.Cell>
                </Table.Row>
            ))}
            </Table.Body>
            <Table.Pagination
                        shadow
                        noMargin
                        align="center"
                        rowsPerPage={10}
                        onPageChange={(page) => console.log({ page })}
                    />
        </Table>
        </Col>
    </Container>

       
     
        </>
    );

}